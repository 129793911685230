import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Logo from "../components/coax-logo"
import SEO from "../components/seo"

const LOS = () => (
    <Layout>
        <SEO title = "LOS"/>
        <div>
            <div>
                <div>WEB DESIGN</div>
                <table>
                    <tr>
                        <th>Custom Hosted Website</th>
                    </tr>
                    <tr>
                        <th>DESIGN</th>
                        <th>$200</th>
                        <th>$500</th>
                        <th>$800</th>
                        <th>$1K</th>
                    </tr>
                    <tr>
                        <td>REVISIONS</td>
                        <td>3</td>
                        <td>4</td>
                        <td>4</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>CONTENT PULL</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>CONTENT</th>
                    </tr>
                    <tr>
                        <td>PAGES</td>
                        <td>1</td>
                        <td>2-4</td>
                        <td>5-7</td>
                        <td>8-10</td>
                    </tr>
                    <tr>
                        <td>SEO</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CONTENT PULL</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CONTENT POPULATION</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>DEVELOPMENT</th>
                    </tr>
                    <tr>
                        <td>MOBILE RESPONSIVE DESIGN</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>3RD PARTY INTEGRATION</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>UNIQUE FRONT-END DEVELOPMENT</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CLIENT/CUSTOMER PORTAL</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>STORE</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>PAYMENT PROCESSING</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th>Custom Self-Hosted Website</th>
                    </tr>
                    <tr>
                        <th>DESIGN</th>
                        <th>$750</th>
                        <th>$1K</th>
                        <th>$2.5K</th>
                        <th>$5K</th>
                        <th>$15K</th>
                    </tr>
                    <tr>
                        <td>REVISIONS</td>
                        <td>4</td>
                        <td>5</td>
                        <td>5</td>
                        <td>6-7</td>
                        <td>8-10</td>
                    </tr>
                    <tr>
                        <td>CONTENT PULL</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>CONTENT</th>
                    </tr>
                    <tr>
                        <td>PAGES</td>
                        <td>11-15</td>
                        <td>16-25</td>
                        <td>26-50</td>
                        <td>51-100</td>
                        <td>101-1000</td>
                    </tr>
                    <tr>
                        <td>SEO</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CONTENT PULL</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CONTENT POPULATION</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>DEVELOPMENT</th>
                    </tr>
                    <tr>
                        <td>MOBILE RESPONSIVE DESIGN</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>STORE</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>3RD PARTY INTEGRATION</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>CLIENT/CUSTOMER PORTAL</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>UNIQUE FRONT-END DEVELOPMENT</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>UNIQUE BACK-END DEVELOPMENT</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>PAYMENT PROCESSING</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>PROJECT MANAGEMENT</th>
                    </tr>
                    <tr>
                        <td>DEDICATED PROJECT MANAGER</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                </table>
            </div>
            <div>
                <div>GRAPHIC DESIGN</div>
                <table>
                    <tr>
                        <th>BRANDING</th>
                        <th>$100</th>
                        <th>$200</th>
                        <th>$250</th>
                        <th>$275</th>
                        <th>$300</th>
                    </tr>
                    <tr>
                        <td>REVISIONS</td>
                        <td>2</td>
                        <td>4</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>LOGO</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>COLOR PALETTE</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>ALTERNATIVE LOGO</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>ICON</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>SOCIAL MEDIA BRANDING</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                </table>
            </div>
            <div>
                <div>DIGITAL DESIGN</div>
                <table>
                    <tr>
                        <th>BRANDING</th>
                        <th>$500</th>
                        <th>$700</th>
                        <th>$1K</th>
                        <th>$1.2K</th>
                        <th>$1.5K</th>
                    </tr>
                    <tr>
                        <td>REVISIONS</td>
                        <td>2</td>
                        <td>4</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>ANIMATED LOGO</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <th>VIDEO</th>
                    </tr>
                    <tr>
                        <td>LENGTH (MIN)</td>
                        <td>0</td>
                        <td>1</td>
                        <td>2-4</td>
                        <td>5</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>TYPOGRAPHY EXPLAINER VIDEO</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>ANIMATED EXPLAINER VIDEO</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>LIVE ACTION EXPLAINER VIDEO</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                        <td>✓</td>
                    </tr>
                    <tr>
                        <td>VOICE OVER</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✕</td>
                        <td>✓</td>
                    </tr>
                </table>
            </div>
        </div> 
    </Layout>
)

export default LOS